import React from 'react'
import { ReactElement } from 'react'

export default function Logo(): ReactElement {
  return (
    <svg
      version="1.1"
      width="180"
      height="34"
      viewBox="0 0 279.98 45.84"
      xmlns="http://www.w3.org/2000/svg"
      fill="#906EEE"
    >
      <g>
        <path d="m108.58 12.48c0 1.16-0.57 1.74-1.72 1.74h-10.87v21.12c0 1.16-0.57 1.74-1.71 1.74-1.15 0-1.87-0.04-2.15-0.13-0.69-0.21-1.04-0.74-1.04-1.61v-21.12h-10.78c-1.16 0-1.74-0.58-1.74-1.74 0-0.79 0.09-1.34 0.26-1.63 0.23-0.41 0.73-0.62 1.48-0.62h26.55c1.15 0 1.72 0.75 1.72 2.25" />
        <path d="m115.5 35.35c0 1.16-0.57 1.74-1.72 1.74-0.5 0-0.93-0.01-1.28-0.03s-0.63-0.05-0.84-0.08c-0.71-0.22-1.06-0.76-1.06-1.63v-23.4c0-0.87 0.35-1.4 1.04-1.61 0.16-0.03 0.42-0.05 0.77-0.08 0.35-0.02 0.81-0.03 1.37-0.03 1.15 0 1.72 0.57 1.72 1.72v23.4z" />
        <path d="m148.12 35.48c0 1.08-0.66 1.61-1.99 1.61-1.46 0-2.3-0.08-2.54-0.22l-20.61-10.87v9.35c0 1.16-0.58 1.74-1.74 1.74-1.18 0-1.86-0.05-2.05-0.15-0.66-0.22-0.99-0.75-0.99-1.59v-23.4c0-0.85 0.33-1.38 0.99-1.59 0.26-0.09 0.95-0.13 2.05-0.13 1.16 0 1.74 0.57 1.74 1.72v9.02l19.85-10.56c0.38-0.12 1.27-0.18 2.67-0.18 1.31 0 1.96 0.53 1.96 1.59 0 0.68-0.39 1.23-1.17 1.65l-19.01 9.95 19.67 10.39c0.78 0.42 1.17 0.98 1.17 1.67" />
        <path d="m179.39 12.48c0 1.16-0.57 1.74-1.72 1.74h-10.87v21.12c0 1.16-0.57 1.74-1.71 1.74-1.15 0-1.87-0.04-2.15-0.13-0.7-0.21-1.04-0.74-1.04-1.61v-21.12h-10.79c-1.16 0-1.74-0.58-1.74-1.74 0-0.79 0.09-1.34 0.27-1.63 0.23-0.41 0.73-0.62 1.48-0.62h26.55c1.14 0 1.72 0.75 1.72 2.25" />
        <path d="m207.22 35.26c0 0.67-0.23 1.24-0.68 1.7-0.46 0.45-1.02 0.68-1.7 0.68-0.94 0-1.7-0.44-2.29-1.32l-4.34-6.31h-20.64l-4.35 6.31c-0.47 0.69-0.98 1.04-1.52 1.04s-1.07-0.18-1.57-0.53c-0.56-0.38-0.84-0.83-0.84-1.35 0-0.16 0.05-0.31 0.13-0.44l15.48-23.66c0.63-0.97 1.72-1.46 3.26-1.46 1.15 0 1.91 0.33 2.29 0.97 0.03 0.04 0.06 0.11 0.1 0.19s0.09 0.18 0.17 0.3l16.41 23.22c0.05 0.1 0.09 0.32 0.09 0.66m-11.89-9.28-7.43-10.96-7.47 10.96h14.9z" />
        <path d="m239.21 35.35c0 1.16-0.57 1.74-1.72 1.74h-15.7c-3.59 0-6.39-0.32-8.4-0.97-3.1-1.07-4.65-3.11-4.65-6.11v-18.06c0-1.15 0.57-1.72 1.72-1.72 1.1 0 1.81 0.04 2.12 0.13 0.7 0.2 1.06 0.73 1.06 1.59v18.06c0 1.4 1.13 2.32 3.4 2.76 0.72 0.19 2.4 0.29 5.03 0.29h15.43c1.14-0.01 1.71 0.76 1.71 2.29" />
        <path d="m247.37 35.35c0 1.16-0.57 1.74-1.72 1.74-0.5 0-0.93-0.01-1.28-0.03s-0.63-0.05-0.84-0.08c-0.7-0.22-1.06-0.76-1.06-1.63v-23.4c0-0.87 0.35-1.4 1.04-1.61 0.16-0.03 0.42-0.05 0.77-0.08 0.35-0.02 0.81-0.03 1.37-0.03 1.15 0 1.72 0.57 1.72 1.72v23.4z" />
        <path d="m279.98 35.48c0 1.08-0.66 1.61-1.99 1.61-1.45 0-2.3-0.08-2.54-0.22l-20.61-10.87v9.35c0 1.16-0.58 1.74-1.74 1.74-1.18 0-1.86-0.05-2.05-0.15-0.66-0.22-0.99-0.75-0.99-1.59v-23.4c0-0.85 0.33-1.38 0.99-1.59 0.26-0.09 0.95-0.13 2.05-0.13 1.16 0 1.74 0.57 1.74 1.72v9.02l19.85-10.56c0.38-0.12 1.27-0.18 2.67-0.18 1.31 0 1.96 0.53 1.96 1.59 0 0.68-0.39 1.23-1.17 1.65l-19.01 9.95 19.67 10.39c0.78 0.42 1.17 0.98 1.17 1.67" />
        <path d="m37.81 0.13c-1.53 0.47-1.44 1.5-1.44 1.5 0.15 0.12 0.29 0.23 0.44 0.34-1.5 0.26-3.06 0.65-4.48 1.24-3.11 1.31-7.85 4.32-10 6.02s-4.49 3.77-6.65 6.31c-0.3 0.35-0.59 0.73-0.87 1.11l0.66-1.38c0.16-0.68 0.32-1.36 0.47-2.04-0.29-1.06-0.57-2.12-0.86-3.19 0 0-2.94 3.9-3.09 6.14s1.24 2.64 1.24 2.64l0.24-0.15c-0.52 0.89-0.9 1.68-1.07 2.22-0.1 0.31-0.18 0.64-0.24 0.98-0.01-0.01-0.01-0.03-0.02-0.04-0.11-0.28-0.29-0.5-0.57-0.62-2.22-0.9-4.06 1.91-4.06 1.91s-2.51 2.39-4.38 6.48c-0.76 1.66-1.41 3.61-1.74 5.8-0.48 3.18-2.47 6.59-0.63 7.84 1.89 1.27 3 0.6 5.72-0.63 0.49-0.22 1.03-0.46 1.63-0.71 6.33-2.6 10.02-6.84 11.62-8.38 1.09-1.04 1.93-2.35 2.01-3.45 0.06-0.75-0.23-1.4-1.02-1.83-0.2-0.1-0.38-0.18-0.56-0.24 0.19-0.15 0.4-0.29 0.64-0.45 0.36-0.23 0.92-0.64 1.55-1.13 0.39 0.38 1.1 0.77 2.34 0.67 2.26-0.19 6.01-3.34 6.01-3.34-0.89-0.3-1.77-0.59-2.66-0.89-0.64 0.13-1.27 0.26-1.91 0.39 0.29-0.27 0.55-0.52 0.75-0.74 1.7-1.78 3.81-3.62 4.88-5.34 1.49-2.4 4.66-4.39 7.01-5.46 0.18 0.36 0.58 0.82 1.42 1.01 1.45 0.33 4.21-1.16 4.21-1.16-1.26-0.19-2.52-0.37-3.78-0.56 0.26-0.07 0.48-0.12 0.65-0.14 2.38-0.22 9.1-1.83 12.58-1.37 9.21 1.2 31.21 21.52-7.82 36.35 18.23-5.2 25.28-12.04 26.56-18.75 2.11-11.1-11.59-21.84-16.64-24.16-1.7-0.78-3.23-1.28-4.68-1.58-3-0.62-5.62-0.4-8.43-0.07-1.11 0.13-3.09 0.23-5.27 0.56 1.71-0.41 3.42-0.82 5.13-1.23 0 0-2.16-0.62-3.8-0.62-0.42 0.01-0.79 0.05-1.09 0.14" />
      </g>
    </svg>
  )
}
